'use strict';

Gri.module({
  name: 'box-slide-list',
  ieVersion: null,
  $el: $('.box-slide-list'),
  container: '.box-slide-list',
  fn: function () {
    boxresize();
    $(window).resize(function () {
      boxresize();
    });
    function boxresize() {
      if($(window).width() < 992) {
        $('.box-slide-list').addClass('owl-carousel').owlCarousel({
          responsiveClass:true,
          dots:true,
          responsive:{
            0:{
              items:1
            },
            992: {
              items:5
            }
          }
        });
      } else {
        $('.box-slide-list').trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded')
      }
    }
  }
});
